body {
  margin: 0;
  font-family: europa, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

#dual_y_line_chart .dual_y_line_chart .dual_chart_body .chart-svg {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), url('../src/assets/HF_logo.svg');
  background-repeat: no-repeat;
  background-size: 20%;
  background-position-x: 9%;
  background-position-y: 79%;
}

.ReactModal__Content {
  margin: auto;
  margin-top: 70px;
  width: 300px;
  height: 200px;
  border-radius: 15px !important;
  bottom: auto !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.session-check-btn {
  align-items: center;
  background-color: #21d97d;
  border: none;
  border-radius: 0.25rem;
  color: #06685e !important;
  cursor: pointer;
  display: flex;
  font-family: Europa, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: none !important;
  height: 48px;
  width: 224px !important;
}

.session-check-btn:hover {
  background-color: #20c773;
  transform: none !important;
}

.session-check-btn:active {
  transform: translate(0, 0) !important;
  transform: scale(1.02) !important;
}

.pricing-plans-title {
  margin-top: -20px;
  color: #21242c;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.abbrevations-links-container {
  padding-bottom: 30px;
  padding-top: 0.2rem;
  text-align: center;
}

.abbrevations-link-number {
  color: #434a59;
  font-family: Europa, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.1rem;
  margin-bottom: 0 !important;
}

.tippy-content {
  color: #000 !important;
  font-weight: 500;
}

.tippy-box[data-theme~='light-border'] {
  border: 1px solid black !important;
}

.tippy-box[data-theme~='light-border'][data-placement^='bottom'] > .tippy-arrow:after {
  border-bottom-color: inherit !important;
}

.tippy-box[data-theme~='light-border'][data-placement^='top'] > .tippy-arrow:after {
  border-top-color: inherit !important;
}

.abbrevations-links-container {
  padding-bottom: 1.1rem;
  padding-top: 0.2rem;
  text-align: center;
}

.abbrevations-link,
.abbrevations-link-number {
  color: #434a59;
  font-family: Europa, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.1rem;
}

.ReactModal__Overlay {
  z-index: 1000000;
  background-color: black !important;
}

.preLogin_title {
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.preLogin_input {
  width: 100%;
  margin-bottom: 20px;
}

.rotate {
  display: none;
}

.chart_title {
  font-weight: 500;
}

.axis-title {
  font-size: 0.8rem;
}

#dual-y-line_chart .chart-svg .axis-title {
  font-size: 1rem;
  font-weight: 500;
  transform: rotate(270deg);
}

.chart-header-update-date {
  margin-bottom: -20px;
  padding-right: 10px;
  float: right;
}

.bar-chart .outer-container .chart-header-container .axis-title {
  font-size: 12px;
  transform: none !important;
}

.ref_zone_labels,
.ref_line_labels {
  font-size: 0.8rem;
  text-shadow: 0 0 10px #fff;
}

.bottom-title {
  font-size: 1rem;
  font-weight: 500;
}

.focus {
  font-size: 0.8rem;
}

.chart_title {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
}

.chart_title a.chart_title_link {
  text-decoration: underline;
}

.chart_title a:hover {
  cursor: pointer;
}

.chart_industry {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 400;
}

/* .dual_chart_body {
  height: calc(100% - 80px);
  width: 90%;
  max-width: 1280px;
}

.dual_chart_body text {
  font-size: 0.8rem;
}

.dual_chart_body .tick line {
  opacity: 0.1;
}
*/

.loader {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.container_breadth .focus,
.container_breadth .axis,
.container_breadth .axis {
  font-size: 0.8rem;
}

.container_breadth .axis line,
.container_breadth .axis path {
  stroke-opacity: 0.2;
}

.MuiCheckbox-root {
  background-color: transparent !important;
}

.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 0 !important;
  background-color: #212529;
}

#okta-sign-in.auth-container .button-primary {
  background: #21d97d !important;
  border-color: #21d97d !important;
  color: #fff !important;
  box-shadow: 0px 2px 6px rgba(33, 36, 44, 0.5) !important;
}

#okta-sign-in.auth-container .button-primary:hover {
  box-shadow: none;
  transform: translate(0px, 2px);
}

.session_error {
  width: 80%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.session_error button {
  border: none;
  outline: none;
  width: 240px;
  padding: 10px 30px;
  background-color: #21d97d;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(33, 36, 44, 0.5);
}

.session_error button:hover {
  box-shadow: none;
  transform: translate(0px, 2px);
}

/* input[type='range']::-webkit-slider-runnable-track {
  height: 1px;
  background: #202020;
  border: none;
  border-radius: 5px;
} */

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #202020 !important;
  margin-top: -4px;
  outline: none;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-moz-range-thumb,
input[type='range']::-webkit-range-thumb {
  background-color: #202020 !important;
}

/* input[type='range']:focus::-webkit-slider-runnable-track {
  background: #202020;
} */

.app_container {
  display: flex;
}

.dualLine_wrap {
  display: flex;
}

a:visited,
a:link {
  text-decoration: none;
  color: unset;
}

.logo {
  height: 100px;
  width: 200px;
}

/* -----------Nav desktop------------ */

.nav_menu {
  background-color: #f4f6fb;
  width: 250px;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  color: #434a59;
}

.nav_menu.active {
  right: 0;
  transition: 350ms;
  z-index: 10;
}

/* ------------------ */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.container {
  width: 70%;
  margin: 0 auto;
}

.container_screener {
  width: 70%;
  margin: 0 auto;
}

.search_view_container {
  width: 40%;
  display: flex;
  margin: 0 auto;
}

.container_breadth {
  width: 70%;
  display: flex;
  margin: 2rem auto;
}

.container_breadth div div div div svg {
  height: calc(var(--vh, 1vh) * 100 - 140px);
  width: 100%;
  touch-action: pan-x;
}

.container_breadth div div div div svg .x-axis > .axis-title {
  transform: unset;
}

.container_breadth div div #line_chart > .chart_title,
.container_breadth div div #dual_y_line_chart > .chart_title {
  text-align: center;
  margin-top: 20px;
}

.cp_wrap {
  width: 80%;
  margin: 0 auto;
}

.cp_wrap h2 {
  font-size: 30px;
  font-weight: bolder;
}

.cp_wrap h6 {
  font-size: 13px;
  font-weight: 600;
}

.cp_wrap hr {
  background-color: #d1d1d1;
  height: 1px;
  border: 0;
  box-shadow: 0px 6px 28px 0px rgba(0, 0, 0, 0.33);
}

/* --------------CTA page---------------- */

.pricing-card-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.pricing-card {
  align-items: flex-start;
  background-color: #fff;
  border: 2px solid #f4f6fb;
  border-radius: 0.25em;
  box-shadow: 0 8px 88px #21242c26;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem 1rem 2rem;
  width: 100%;
}

.pricing-card-title {
  align-self: center;
  color: #434a59;
  font-family: Europa, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.6rem;
  margin-bottom: 0.3rem;
}

.pricing-card-price {
  align-self: center;
  color: #000;
  font-family: Europa, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.5rem;
  margin-bottom: 0;
}

.pricing-card-price-subtite {
  align-self: center;
  color: #434a59;
  font-family: Europa, sans-serif;
  font-size: 0.87rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
}

.pricing-check-points-container {
  height: auto;
}

.react-reveal {
  max-width: 400px;
  margin-top: 50px;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.material-icons-small {
  font-family: Material Icons;
  font-size: 1rem;
}

.pricing-check-icon {
  align-self: baseline;
  color: #21d97d;
  margin-right: 0.625rem;
}

.pricing-check-title {
  color: #434a59;
  font-family: Europa, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5rem;
}

.pricing-card-button {
  height: 3rem;
  margin-top: 3rem;
  text-decoration: none;
  width: 100%;
}

.pricing-card-button:active {
  background-color: #20c773;
  color: #06685e;
}

.pricing-card-button:focus {
  background-color: #20c773;
  color: #06685e;
}

.btn-custom,
.pricing-card-button {
  align-items: center;
  background-color: #21d97d;
  border: none;
  border-radius: 0.25rem;
  color: #434a59;
  color: #06685e;
  cursor: pointer;
  display: flex;
  font-family: Europa, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.pricing-card-button-disabled {
  height: 3rem;
  margin-top: 3rem;
  text-decoration: none;
  width: 100%;
}

.btn-custom,
.pricing-card-button-disabled {
  align-items: center;
  background-color: grey;
  border: none;
  border-radius: 0.25rem;
  color: black;
  cursor: not-allowed !important;
  display: flex;
  font-family: Europa, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.pricing-card-super-container {
  display: flex;
  justify-content: center;
  min-height: 405px;
  padding: 0 2px !important;
  margin-bottom: 50px;
}

.cta_wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cta_wrap img {
  width: 30%;
  margin-bottom: 100px;
}

.ctaPlan_wrap {
  margin-top: 2rem;
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ctaPlan_wrap .break {
  width: 100%;
}

.ctaBtn_wrap {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ctaBtn_wrap p {
  font-weight: bold;
}

.ctaBtn_wrap button {
  border: none;
  outline: none;
  width: 240px;
  padding: 10px 30px;
  background-color: #21d97d;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(33, 36, 44, 0.5);
}

.ctaBtn_wrap button:hover {
  box-shadow: none;
  transform: translate(0px, 2px);
}

.ctaBtn_wrap button:disabled {
  cursor: not-allowed;
  background-color: #dddddd;
}

.ctaBtn_wrap:nth-of-type(2) {
  margin: 0 20px;
}

/* -------------- */

/* --------------Success page------------ */

.success_wrap {
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.success_wrap img {
  width: 30%;
  margin-bottom: 100px;
}

/* -------------- */

/* --------------Redirect page----------- */

.redirect_wrap {
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.redirect_wrap img {
  width: 30%;
  margin-bottom: 100px;
}

/* ------------- */

/* --------------Guide page-------------- */

.guide_wrap {
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  color: #434a59;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 80px;
}

.guide_wrap > div {
  width: 65%;
  margin: 0 auto;
}

.guide_wrap hr {
  border: 1px solid #afb9cf;
  width: 60%;
  margin: 40px auto;
}

.guide_wrap h1 {
  font-size: 48px;
  text-align: center;
  margin: 100px 0 80px;
}

.guide_wrap h4 {
  margin: 40px 0 20px;
  text-align: center;
}

.guide_wrap .examples h4 {
  margin-bottom: 80px;
}

.guide_wrap .main_image {
  margin: 100px auto 50px auto;
  width: 100%;
}

.guide_wrap img {
  margin: 50px auto;
  display: flex;
}

.guide_wrap .examples img {
  width: 100%;
}

.guide_wrap ol li p {
  margin: 30px 0 0 0;
}

.guide_wrap .table_of_content p {
  color: #7961f2;
  text-decoration: underline;
}

.guide_wrap .table_of_content p:hover {
  cursor: pointer;
}

.guide_wrap .content_list {
  display: flex;
  flex-direction: column;
}

.guide_wrap .content_list p {
  margin: 0;
}

.guide_wrap .content_list span {
  color: #7961f2;
  text-decoration: none;
}

.guide_wrap .content_list span:hover {
  cursor: pointer;
}

.logo_guide {
  display: none !important;
}

/* ------------- */

/* --------------Account page------------ */

.current_plan {
  display: flex;
  justify-content: space-between;
}

.btns {
  display: flex;
  flex-direction: column;
}

.btnUpdate {
  border: none;
  outline: none;
  background-color: #d148db;
  color: #ffffff;
  padding: 10px 35px;
  border-radius: 5px;
  box-shadow: -5px 5px 15px -3px rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}

.btnCancel {
  border: none;
  outline: none;
  background-color: #ffffff;
  color: #202020;
  padding: 10px 35px;
  border-radius: 5px;
  box-shadow: -5px 5px 15px -3px rgba(0, 0, 0, 0.75);
}

.account_wrap {
  display: flex;
}

.account_nav_desktop {
  display: block;
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;
}

.account_nav_desktop div {
  cursor: pointer;
  height: 200px;
  width: 200px;
  background-color: #d3d3d3;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

/* ----------- */

/* ------------Contact us----------- */

.confirm_message {
  color: #21242c;
  font-size: 14px;
  margin-bottom: 0;
}

.contact_wrap {
  display: flex;
  color: #21242c;
}

.form_wrap img {
  display: none;
}

.contactUs_wrap {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form_wrap {
  width: 460px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form_wrap h3 {
  font-size: 30px;
  font-weight: bold !important;
}

.form_wrap form {
  display: flex;
  flex-direction: column;
}

.form_wrap label {
  margin-top: 30px;
  font-size: 14px;
}

.form_wrap form div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form_wrap form div div {
  width: 48%;
}

.form_wrap form p {
  font-size: 14px;
}

.form_wrap input {
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-size: 12px;
  line-height: normal;
  border-radius: 2px;
}

.form_wrap textarea {
  width: 100%;
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: #fafafa;
  color: #000;
  font-size: 12px;
  line-height: normal;
  border-radius: 2px;
}

.form_wrap button {
  width: 30%;
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  background-color: #21242c;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
}

/* ---------------- */

/* ------------TOS page------------- */

.tos_container {
  height: 100vh;
  width: 80%;
  z-index: 1;
  overflow-y: auto;
}

.tos_container > div {
  width: 50%;
  margin: 0 auto;
}

/* .tos_container::-webkit-scrollbar {
  width: 10px;
  left: -10px;
} */

.tos_container img {
  display: none;
}

.tos_wrap {
  font-size: 18px;
}

.tos_wrap h1 {
  font-size: 48px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 80px;
}

/* --------------- */

/* ------------Privacy page---------- */

.privacy_container {
  height: 100vh;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
}

.privacy_iframe {
  margin-top: -10px;
  width: 100%;
  height: 100%;
}

.privacy_container > div {
  width: 50%;
  margin: 0 auto;
}

.privacy_container img {
  display: none;
}

.privacy_wrap {
  font-size: 18px;
}

.privacy_wrap h1 {
  font-size: 48px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 80px;
}

/* -------------- */

/* ------------Not found------------ */

.notFound_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.notFound_wrap h1 {
  color: #21242c;
  font-size: 6rem;
}

.notFound_wrap p {
  font-size: 2rem;
  color: #7684a2;
  margin-bottom: 60px;
}

/* ---------------- */

/* ---------------Trading view page------------ */

.tradingview_wrapp {
  width: 70%;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* --------------- */

@media screen and (max-width: 480px) {
  .focus {
    font-size: 0.8rem;
  }

  .axis-title {
    font-size: 0.8rem;
  }

  .bottom-title {
    font-size: 0.8rem;
  }

  .bar-chart .outer-container .chart-header-container .axis-title {
    font-size: 12px;
    letter-spacing: 0px;
  }

  .bar-chart .outer-container .chart-footer-container .axis-title {
    font-size: 12px;
    letter-spacing: 0px;
  }

  /*   .dual_chart_body {
    padding: 0 5px;
  }

  .dual_chart_body .chart-svg {
    touch-action: pan-x;
  }
 */
  .line_chart .chart_body .chart_svg,
  .dual_y_line_chart .chart_body .chart_svg {
    touch-action: pan-x;
  }

  .cta_wrap img {
    width: 80%;
    margin-bottom: 50px;
  }

  .ctaPlan_wrap {
    width: 80%;
    flex-direction: column;
  }

  .ctaBtn_wrap button,
  form {
    width: 100%;
  }

  .ctaBtn_wrap:nth-of-type(2) {
    margin: 20px 0;
  }

  .btn-group > .btn.active,
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn:active + .btn {
    background-color: #212529 !important;
    color: #ffffff !important;
  }

  .btn-group > .btn:hover {
    background-color: unset !important;
    color: #212529 !important;
  }

  .app_container {
    display: unset;
  }

  .container_breadth {
    margin: 0rem auto;
  }

  .account_wrap {
    display: unset;
  }

  .container {
    width: 100%;
  }

  .search_view_container {
    width: 100%;
  }

  .container_breadth {
    width: 95%;
  }

  .nav_wrap,
  .account_nav_desktop {
    display: none;
  }

  .bottom_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bottom_nav img {
    margin: 0 0 40px 0;
  }

  .account_nav {
    width: 80%;
    margin: 0 auto;
    background-color: #d3d3d3;
  }

  .account_nav ul {
    margin: 0;
    padding: 20px 0;
    display: table-cell;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    position: relative;
  }

  .account_nav li {
    width: 80%;
    margin: 5px auto;
    padding: 10px 0;
    background-color: #ffffff;
    border: 1px solid #202020;
    color: #202020;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
  }

  .contact_wrap {
    height: calc(100vh - 120px);
  }

  .form_wrap {
    width: 80%;
    height: 900px;
    justify-content: unset;
  }

  .form_wrap button {
    width: 100%;
  }

  .form_wrap label {
    margin: 10px 0;
  }

  .form_wrap h3 {
    margin-top: 80px;
  }

  .contact_wrap h3 {
    margin-top: 50px;
  }

  .guide_wrap h1 {
    text-align: center;
    margin: 100px 0 80px;
    font-size: 30px;
  }

  .contact_wrap img {
    display: block;
    width: 75%;
    margin: 40px auto;
  }

  .success_wrap img {
    width: 70%;
    margin-bottom: 50px;
  }

  .success_wrap h1 {
    font-size: 16px;
  }

  .success_wrap p {
    font-size: 14px;
  }

  .tos_wrap h1 {
    font-size: 30px;
  }

  .tos_container {
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .tos_container > div {
    width: 100%;
  }

  .tos_container img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .tos_wrap {
    font-size: 18px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .privacy_wrap h1 {
    font-size: 30px;
  }

  .privacy_container {
    z-index: 0;
    width: 100%;
  }

  .privacy_container > div {
    width: 100%;
    margin: 0 auto;
  }

  .privacy_container img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .privacy_wrap {
    font-size: 18px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .notFound_wrap h1 {
    font-size: 2.5rem;
  }

  .notFound_wrap p {
    font-size: 1rem;
  }

  .redirect_wrap img {
    width: 60%;
  }

  .guide_wrap {
    width: 100%;
    font-size: 16px;
  }

  .guide_wrap > div {
    width: 90%;
    margin: 0 auto;
  }

  .guide_wrap img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .logo_guide {
    display: block !important;
    width: 50%;
    margin: 40px auto;
  }

  .tradingview_wrapp {
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 40px);
    width: 100%;
    margin: 0;
    padding: 15px;
  }

  .container_breadth div div div div svg {
    height: calc(var(--vh, 1vh) * 100 - 80px);
    touch-action: pan-x;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .cta_wrap img {
    width: 50%;
    margin-bottom: 50px;
  }

  .ctaPlan_wrap {
    width: 80%;
    flex-direction: column;
  }

  .ctaBtn_wrap button,
  form {
    width: 100%;
  }

  .ctaBtn_wrap:nth-of-type(2) {
    margin: 20px 0;
  }

  .app_container {
    display: unset;
  }

  .container_breadth {
    margin: 0rem auto;
  }

  .account_wrap {
    display: unset;
  }

  .container {
    width: 100%;
  }

  .search_view_container {
    width: 70%;
  }

  .container_breadth {
    width: 100%;
  }

  .nav_wrap,
  .account_nav_desktop {
    display: none;
  }

  .bottom_nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bottom_nav img {
    margin: 0 0 40px 0;
  }

  .guide_wrap img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .guide_wrap h1 {
    text-align: center;
    margin: 100px 0 80px;
    font-size: 48px;
  }

  .account_nav {
    width: 80%;
    margin: 0 auto;
    background-color: #d3d3d3;
  }

  .account_nav ul {
    margin: 0;
    padding: 20px 0;
    display: table-cell;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    position: relative;
  }

  .account_nav li {
    width: 80%;
    margin: 5px auto;
    padding: 10px 0;
    background-color: #ffffff;
    border: 1px solid #202020;
    color: #202020;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
  }

  .form_wrap {
    width: 60%;
    height: 900px;
  }

  .contact_wrap h3 {
    margin-top: 50px;
  }

  .contact_wrap img {
    display: block;
    width: 100%;
    margin: 50px auto;
  }

  .nav_menu {
    width: 43%;
  }

  .nav_menu ul {
    margin-top: 20%;
    width: 80%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
  }

  .nav_menu ul li {
    margin: 10px 0;
  }

  .tos_container {
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .tos_container > div {
    width: 100%;
  }

  .tos_container img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .tos_wrap {
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .privacy_container {
    z-index: 0;
    width: 100%;
  }

  .privacy_container > div {
    width: 100%;
    margin: 0 auto;
  }

  .privacy_container img {
    display: block;
    width: 60%;
    margin: 40px auto;
  }

  .privacy_wrap {
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .notFound_wrap h1 {
    font-size: 4rem;
  }

  .notFound_wrap p {
    font-size: 1.5rem;
  }

  .guide_wrap {
    width: 100%;
  }

  .guide_wrap > div {
    width: 80%;
    margin: 0 auto;
  }

  .logo_guide {
    display: block !important;
    width: 50%;
    margin: 40px auto;
  }

  .tradingview_wrapp {
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 80px);
    width: 100%;
    margin: 0;
    padding: 15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .focus {
    font-size: 0.8rem;
  }

  .axis-title {
    font-size: 1rem;
  }

  .bottom-title {
    font-size: 1rem;
  }

  .bar-chart .outer-container .chart-header-container .axis-title {
    font-size: 12px;
    letter-spacing: 0px;
  }

  .bar-chart .outer-container .chart-footer-container .axis-title {
    font-size: 12px;
    letter-spacing: 0px;
  }
}

@media screen and (min-width: 970px) and (max-width: 1150px) {
  .container_screener {
    width: 94%;
  }
}

@media screen and (min-width: 890px) and (max-width: 970px) {
  .container_screener {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 890px) {
  .nav_wrap {
    display: none;
  }

  .nav_menu {
    width: 43%;
  }

  .container_breadth {
    margin: 0rem auto;
    height: calc(100vh - 80px) !important;
    min-height: -webkit-fill-available;
    min-height: stretch;
  }

  .nav_menu ul {
    margin-top: 20%;
    width: 80%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 0;
  }

  .nav_menu ul li {
    margin: 10px 0;
  }

  .contact_wrap h3 {
    margin-top: 50px;
  }

  .contact_wrap img {
    display: block;
    width: 100%;
    margin: 50px auto;
  }

  .form_wrap {
    width: 50%;
    padding-bottom: 150px;
  }

  .tos_container {
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .tos_container > div {
    width: 100%;
  }

  .tos_container img {
    display: block;
    width: 50%;
    margin: 50px auto;
  }

  .tos_wrap {
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .privacy_container {
    z-index: 0;
    width: 100%;
  }

  .privacy_container > div {
    width: 100%;
    margin: 0 auto;
  }

  .privacy_container img {
    display: block;
    width: 50%;
    margin: 50px auto;
  }

  .privacy_wrap {
    font-size: 18px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 100px;
    height: unset;
    overflow-y: unset;
  }

  .guide_wrap {
    width: 100%;
  }

  .guide_wrap > div {
    width: 80%;
    margin: 0 auto;
  }

  .notFound_wrap h1 {
    font-size: 4rem;
  }

  .notFound_wrap p {
    font-size: 1.5rem;
  }

  .logo_guide {
    display: block !important;
    width: 50%;
    margin: 40px auto;
  }

  .tradingview_wrapp {
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 80px);
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .search_view_container {
    width: 50%;
  }

  .container_screener {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .cta_wrap img {
    width: 50%;
  }

  .container_breadth {
    width: 90%;
  }

  .container_breadth .chart-container {
    margin: auto 0;
    height: calc(100vh - 8rem) !important;
    min-height: -webkit-fill-available;
    min-height: stretch;
  }

  .ctaBtn_wrap:nth-of-type(2) {
    margin: 0 10px;
  }
}

@media screen and (min-width: 881px) and (max-width: 1200px) {
  .legend-text {
    letter-spacing: 0;
  }
}

@media screen and (min-width: 891px) and (max-width: 1200px) {
  .logo {
    width: 150px;
  }

  .container_breadth {
    margin: 2rem auto;
    height: unset !important;
  }
}

/*----------Landscape--------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  nav ul {
    padding-left: 10% !important;
  }
}

/* Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 640px) and (orientation: landscape) {
  .rotate {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    touch-action: none;
  }

  .rotateIcon {
    height: 50px !important;
    width: 50px !important;
  }

  .chart-container,
  #dual_y_line_chart {
    display: none;
  }

  .container_breadth {
    margin: 0;
  }
}

@media only screen and (min-device-width: 641px) and (max-device-width: 768px) and (orientation: landscape) {
  .rotate {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    touch-action: none;
  }

  .rotateIcon {
    height: 50px !important;
    width: 50px !important;
  }

  .chart-container,
  #dual_y_line_chart {
    display: none;
  }

  .container_breadth {
    margin: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .rotate {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    touch-action: none;
  }

  .rotateIcon {
    height: 50px !important;
    width: 50px !important;
  }

  .chart-container,
  #dual_y_line_chart {
    display: none;
  }

  .container_breadth {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .pricing-card-container {
    display: block;
    height: 100%;
    padding-bottom: 0;
  }

  .pricing-card {
    padding: 1rem 1rem 2rem;
  }

  .pricing-card-title {
    font-size: 1.25rem;
    margin-bottom: 1.8rem;
  }

  .pricing-card-price {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  .pricing-check-points-container {
    height: 10.6rem;
    margin-top: 1.125rem;
  }

  .pricing-card-super-container {
    display: block;
  }
}

@media (min-width: 768px) {
  .pricing-card-container {
    display: block;
    height: 100%;
    padding-bottom: 0;
  }

  .pricing-card {
    padding: 1rem 1rem 2rem;
  }

  .pricing-card-title {
    font-size: 1.25rem;
    margin-bottom: 0.8rem;
  }

  .pricing-card-price {
    font-family: Europa, sans-serif;
    font-size: 2rem;
    font-style: normal;
    letter-spacing: 0;
    line-height: 2.5rem;
  }

  .pricing-check-points-container {
    height: 10.6rem;
  }

  .pricing-card-super-container {
    display: block;
    padding: 0 21px !important;
  }
}

@media (max-width: 1500px) {
  .ctaPlan_wrap {
    width: 80%;
  }
}

@media (max-width: 1300px) {
  .ctaPlan_wrap {
    width: 85%;
  }
}

@media (max-width: 1100px) {
  .ctaPlan_wrap {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .pricing-plans-title {
    font-size: 2rem !important;
    letter-spacing: 0;
    line-height: 2.5rem;
    margin-bottom: 0.5rem !important;
    margin-top: 1rem;
  }

  #dual_y_line_chart .dual_y_line_chart .dual_chart_body .chart-svg {
    background-position-x: 11%;
  }

  .container_screener {
    width: 100%;
  }
}

@media (max-width: 550px) {
  #dual_y_line_chart .dual_y_line_chart .dual_chart_body .chart-svg {
    background-position-x: 15%;
  }
}

@media (max-width: 400px) {
  #dual_y_line_chart .dual_y_line_chart .dual_chart_body .chart-svg {
    background-position-x: 19%;
  }
}

@media (max-width: 300px) {
  #dual_y_line_chart .dual_y_line_chart .dual_chart_body .chart-svg {
    background-position-x: 24%;
  }
}

@media (max-width: 1200px) {
  .pricing-plans-title {
    font-size: 3rem;
    letter-spacing: 0;
    line-height: 3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1200px) {
  .abbrevations-links-container {
    padding-bottom: 5rem;
    padding-top: 0.5rem;
  }
}

@media (max-width: 1086px) {
  .tippy-box {
    max-width: 220px !important;
  }
}

@media (max-width: 767px) {
  .abbrevations-links-container {
    padding-bottom: 2.15rem;
    padding-top: 0.5rem;
  }

  .tippy-box {
    max-width: 320px !important;
  }
}

@media (max-width: 350px) {
  .tippy-box {
    max-width: 220px !important;
  }
}
